import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <section id="cta" className="cta">
      <div className="container">
        <h2>Prêt à découvrir nos services ?</h2>
        <p>Contactez-nous dès aujourd'hui pour obtenir un devis gratuit et voir comment nous pouvons vous aider à garder votre espace propre et organisé.</p>
        <Link to="/contact" className="btn">Contactez-nous</Link>
      </div>
    </section>
  );
};

export default CallToAction;



