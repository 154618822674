import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section id="home" className="hero">
      <div className="container">
        <h2>Bienvenue à l'Entreprise de Nettoyage</h2>
        <p>Votre partenaire de confiance pour la propreté.</p>
        <Link to="/contact" className="btn">Obtenez un devis gratuit</Link>
      </div>
    </section>
  );
};

export default Hero;






