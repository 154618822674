import React from 'react';

const Features = () => {
  return (
    <section id="features" className="features">
      <div className="container">
        <h2>Nos Caractéristiques</h2>
        <div className="feature-item">
          <h3>Qualité</h3>
          <p>Nous garantissons un service de qualité supérieure pour toutes nos prestations.</p>
        </div>
        <div className="feature-item">
          <h3>Fiabilité</h3>
          <p>Nos équipes sont ponctuelles et fiables, vous pouvez compter sur nous.</p>
        </div>
        <div className="feature-item">
          <h3>Écologique</h3>
          <p>Nous utilisons des produits respectueux de l'environnement pour le bien de tous.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;
