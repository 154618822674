import React from 'react';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2>À propos de nous</h2>
        <p>Notre mission est de fournir des services de nettoyage de premier ordre adaptés à vos besoins...</p>
      </div>
    </section>
  );
};

export default About;
