import React from 'react';

const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <h2>Nos Services</h2>
        <div className="service">
          <h3>Nettoyage Résidentiel</h3>
          <p>Services de nettoyage détaillés pour votre maison...</p>
        </div>
        <div className="service">
          <h3>Nettoyage Commercial</h3>
          <p>Nettoyage professionnel pour votre entreprise...</p>
        </div>
        <div className="service">
          <h3>Nettoyage Spécialisé</h3>
          <p>Services de nettoyage spécialisés pour des besoins uniques...</p>
        </div>
      </div>
    </section>
  );
};

export default Services;

