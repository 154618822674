import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Contact = () => {
  return (
    <section className="contact">
      <div className="container">
        <div className="contact-wrapper">
          <div className="contact-form">
            <h2>Contactez-nous</h2>
            <form className="form">
              <div className="form-group">
                <label htmlFor="name">Nom:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="service">Service:</label>
                <div className="custom-dropdown">
                  <select id="service" name="service" required>
                    <option value="residential">Nettoyage Résidentiel</option>
                    <option value="commercial">Nettoyage Commercial</option>
                    <option value="specialized">Nettoyage Spécialisé</option>
                  </select>
                  <div className="dropdown-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M7 10l5 5 5-5z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <div className="form-group center">
                <button type="submit" className="btn">Envoyer</button>
              </div>
            </form>
          </div>
          <div className="contact-info">
            <div className="info-box">
              <div className="info-item">
                <FaEnvelope className="icon" />
                <p>Email: info@cleaningcompany.com</p>
              </div>
              <div className="info-item">
                <FaPhone className="icon" />
                <p>Téléphone: 123-456-7890</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;






