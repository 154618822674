import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section id="testimonials" className="testimonials">
      <div className="container">
        <h2>Témoignages</h2>
        <Slider {...settings}>
          <div className="testimonial-item">
            <p>"Service incroyable, toujours à l'heure et très professionnel." - Jean Dupont</p>
          </div>
          <div className="testimonial-item">
            <p>"Je recommande vivement cette entreprise de nettoyage." - Marie Curie</p>
          </div>
          <div className="testimonial-item">
            <p>"Le personnel est amical et efficace. Travail impeccable." - Albert Einstein</p>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;





