import React from 'react';

const Pricing = () => {
  return (
    <section id="pricing" className="pricing">
      <div className="container">
        <h2>Nos Tarifs</h2>
        <div className="pricing-plan">
          <h3>Plan de base</h3>
          <p>€50 / mois</p>
          <ul>
            <li>Nettoyage hebdomadaire</li>
            <li>Produits fournis</li>
            <li>Satisfaction garantie</li>
          </ul>
        </div>
        <div className="pricing-plan">
          <h3>Plan Premium</h3>
          <p>€100 / mois</p>
          <ul>
            <li>Nettoyage bi-hebdomadaire</li>
            <li>Produits premium fournis</li>
            <li>Satisfaction garantie</li>
          </ul>
        </div>
        <div className="pricing-plan">
          <h3>Plan Entreprise</h3>
          <p>€200 / mois</p>
          <ul>
            <li>Nettoyage quotidien</li>
            <li>Produits premium fournis</li>
            <li>Satisfaction garantie</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
